import { SectionHeading, SectionTitle } from '../styles'
import CardItem from './CardItem'
import { Container, ItemContainer } from './styles'
import { EventTracking } from '@/helpers/eventTracking'
import { useSession } from 'next-auth/react'
import Link from 'next/link'
import { useEffect, useState } from 'react'

const VerticalContents = ({ sectionTitle, contents, segmentId, viewType }) => {
    const { data: session } = useSession()
    const [sectionClick, onSectionClick] = useState(false)

    // const onSectionClick = () => {
    //     EventTracking('lihatSemua_page_opened', {
    //         segmentId: segmentId,
    //         segmentTitle: sectionTitle,
    //     })
    //     // history.push(`/segment/${segmentId}`)
    // }

    useEffect(() => {
        if (sectionClick && session?.token) {
            EventTracking(
                'lihatSemua_page_opened',
                {
                    segmentId: segmentId,
                    segmentTitle: sectionTitle,
                },
                session
            )
        }
    }, [sectionClick, sectionTitle, segmentId, session])

    return (
        <Container>
            <Link href={`/segment/${segmentId}`} prefetch={false}>
                <a>
                    <SectionHeading>
                        <SectionTitle>{sectionTitle}</SectionTitle>

                        <img
                            style={{ cursor: 'pointer' }}
                            src={'/img/arrow-right.svg'}
                            alt="arrow-right"
                            onClick={() => onSectionClick(true)}
                            loading="lazy"
                        />
                    </SectionHeading>
                </a>
            </Link>
            <ItemContainer>
                {contents.length &&
                    contents.map((item) => (
                        <CardItem
                            key={item.id + Math.random(5)}
                            id={item.id}
                            image={item.image}
                            imageMeta={item?.imageMeta}
                            title={item.title}
                            subTitle={item.subTitle}
                            videoUrl={item?.videoUrl}
                            author={item.author}
                            duration={item.duration}
                            entityType={item.entityType}
                            viewType={viewType}
                            category={item.category}
                            sectionTitle={sectionTitle}
                            isPremium={item.isPremium}
                            earlyAccessFinishDate={item.earlyAccessFinishDate}
                        />
                    ))}
            </ItemContainer>
        </Container>
    )
}

export default VerticalContents
